<div class='view' style="background-color: sandybrown; width: 100%; height: 100%;">
    <div class='graph' [ngStyle]="{'background-color': 'teal', 'width': isHidden ? '100%' : 'calc(100% - 300px)'}">
        <!-- <button class='menuButton' mat-raised-button (click)="menuButtonClicked()" [ngStyle]="{'width': '70px'}">{{isHidden ? 'Show' : 'Hide'}}</button>  -->
        <app-cytoscape-graph (nodeSelected)="onNodeSelected($event)" 
        (edgeSelected)="onEdgeSelected($event)"></app-cytoscape-graph> 
    </div>

    <div class='menu' style="background-color: beige;"
        *ngIf="!isHidden"
     [ngStyle]="{'width': isHidden ? '0px' : '300px'}"> 
     <h1>{{menuMessage}}</h1>
     
        <mat-form-field appearance="fill">
            <mat-label>Microform</mat-label>
            <mat-select>
            <mat-option *ngFor="let microform of microforms" [value]="microform.value">
                {{microform.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="fill">
            <mat-label>Billboard</mat-label>
            <mat-select>
            <mat-option *ngFor="let billboard of billboards" [value]="billboard.value">
                {{billboard.viewValue}}
            </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>