import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NodeSingular, EdgeCollection, ElementsDefinition } from 'cytoscape';
import * as cytoscape from 'cytoscape';
import * as edgehandles from 'cytoscape-edgehandles';
import { style } from '@angular/animations';
import { GraphService } from 'src/app/services/graph-service/graph.service';

@Component({
  selector: 'app-cytoscape-graph',
  templateUrl: './cytoscape-graph.component.html',
  styleUrls: ['./cytoscape-graph.component.css'],
  providers: [GraphService]
})

export class CytoscapeGraphComponent implements OnInit {  
  constructor(private service: GraphService) {}

  @Output() nodeSelected = new EventEmitter<string>();
  @Output() edgeSelected = new EventEmitter<string>();

  ngOnInit(): void { 
    if (document == null) { 
      return; 
    }

    var _this = this;
    document.addEventListener('DOMContentLoaded', function() { 
      cytoscape.use ( edgehandles as any ); 
      var cy = cytoscape({
        container: document.getElementById('graph'), 
        layout: {
          name: 'preset'
        },
        zoom: 1,
        minZoom: 0.5,
        maxZoom: 2,
        pan: { x: 0, y: 0},
        style:  _this.service.style(), 
        elements: _this.service.elements(),
      });
      
      cy.on('tap', function(event){
        var target = event.target;

        if (target.isNode()) {  
          const node = target as cytoscape.NodeSingular;
          _this.nodeSelected.emit("Node with id: " + target.id());

        } else if (target.isEdge()) { 
          var edge = target as cytoscape.EdgeSingular; 
          _this.edgeSelected.emit("Edge with source node id: " + edge.source().id() + " and target node id: " + target.target().id());
        } 
      });

      var eh = cy.edgehandles({
        snap: true,
        handlePosition: function(node) { 
          return 'right middle';
        },
        loopAllowed: function(node) { 
          return true;
        },
        hoverover: function(node) {
          console.log('hover over node');
        },
        start: function(sourceNode) {
          _this.onStartCallback(sourceNode);
        },
        complete: function(source: NodeSingular, target: NodeSingular, edges: EdgeCollection) {
          _this.onCompleteCallback(source, target, edges);
        }, 
        cancel: function(source: NodeSingular, targets: any) {
          
          _this.onCancelCallBack(source, targets);
        },
      });
    });  
  }

  onStartCallback(source: NodeSingular) {
    console.log('Started from: ' + source.id());
  }

  onCompleteCallback(source: NodeSingular, target: NodeSingular, edges: EdgeCollection): void {      
    console.log('source: ' +  source.id() + ', target: ' + target.id() + ', edges: ');
  }  

  onCancelCallBack(source: NodeSingular, calledTargets: any) {
    console.log('cancelled ');
  } 
}
