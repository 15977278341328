import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})

export class GraphService {  
  constructor() { 
    console.log('UUID ' + uuidv4());
  } 

  elements(): any { 
    return {
        nodes: [
        { 
          data: { id: 'a', name: 'A' },
          renderedPosition: {
            x: 283,
            y: 356
          }, 
        },
        {  
          renderedPosition: {
            x: 500,
            y: 272
          }, 
          data: { id: 'b', name: 'B' }
        },
        { 
          renderedPosition: {
            x: 450,
            y: 564
          },
          data: { id: 'c', name: 'C' } 
        },
        { 
          renderedPosition: {
            x: 750,
            y: 188
          },
          data: { id: 'd', name: 'D' } }, 
        { 
          renderedPosition: {
            x: 1325,
            y: 524
          },
          data: { id: 'e', name: 'E' } }
      ],
      edges: [
        { data: { source: 'a', target: 'b' } },
        { data: { source: 'b', target: 'd' } },
        { data: { source: 'a', target: 'c' } },
        { data: { source: 'd', target: 'c' } },
        { data: { source: 'c', target: 'c' } },
        { data: { source: 'c', target: 'e' } },
        { data: { source: 'a', target: 'e' } }
      ]
    }
  }  

  style(): any {
    return [
      {
        selector: 'node[name]',
        style: {
          'content': 'data(name)'
        }
      }, 
      {
        selector: 'edge',
        style: {
          'curve-style': 'bezier',
          'target-arrow-shape': 'triangle'
        }
      },
      {
        selector: '.eh-handle',
        style: {
          'background-color': 'red',
          'width': 12,
          'height': 12,
          'shape': 'ellipse',
          'overlay-opacity': 0,
          'border-width': 12,
          'border-opacity': 0
        }
      },
  
      {
        selector: '.eh-hover',
        style: {
          'background-color': 'red'
        }
      },
  
      {
        selector: '.eh-source',
        style: {
          'border-width': 2,
          'border-color': 'red'
        }
      },
  
      {
        selector: '.eh-target',
        style: {
          'border-width': 2,
          'border-color': 'red'
        }
      },
  
      {
        selector: '.eh-preview, .eh-ghost-edge',
        style: {
          'background-color': 'red',
          'line-color': 'red',
          'target-arrow-color': 'red',
          'source-arrow-color': 'red'
        }
      },
  
      {
        selector: '.eh-ghost-edge.eh-preview-active',
        style: {
          'opacity': 0
        }
      }
    ]
  }
}
