import { Component, NgModule } from '@angular/core';

interface Microform {
  value: string;
  viewValue: string;
}

interface Billboard {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'angular-graph';
  selectedValue: string = '';
  menuMessage: string = '';
  isHidden: boolean = true;
  
  microforms: Microform[] = [
    {value: 'microform-0', viewValue: 'Microform 0'},
    {value: 'microform-1', viewValue: 'Microform 1'},
    {value: 'microform-2', viewValue: 'Microform 2'}
  ];

  billboards: Billboard[] = [
    {value: 'billboard-0', viewValue: 'Billboard 0'},
    {value: 'billboard-1', viewValue: 'Billboard 1'},
    {value: 'billboard-2', viewValue: 'Billboard 2'}
  ];

  menuButtonClicked(): void {
    this.isHidden = !this.isHidden;
    console.log("Is menu hidden " + this.isHidden);
  }

  onNodeSelected(message: string) {
    this.isHidden = false; 
    this.menuMessage = message;
  }

  onEdgeSelected(message: string) {
    this.isHidden = false;
    this.menuMessage = message;

  }
}
